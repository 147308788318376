<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <tchtree v-model="searchForm.class_ids" @changeType="changeType"></tchtree>
        <!--        <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
                  style="width:200px" clearable>
        </el-input>-->

        <!--        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary" @click="">搜索</el-button>-->
      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>常用表格下载</el-breadcrumb-item>
          <el-breadcrumb-item>表格下载</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <el-row :gutter="12">
        <el-col v-if="$store.state.pmid != 214" :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>91JOB</span>
            </div>
            <div v-if="$store.state.pmid == 219" class="content">
              <div class="item">1. <el-link type="primary" @click="handleDownload219('sxmc')">生源信息表 <i
                    class="el-icon-download"></i></el-link>
              </div>

            </div>
            <div v-else class="content">
              <div class="item">1. <el-link type="primary" disabled>生源信息表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">2. <el-link type="primary" disabled>就业推荐表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">3. <el-link type="primary" disabled>毕业去向 <i class="el-icon-download"></i></el-link>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col v-if="$store.state.pmid != 214" :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>联合学院</span>
            </div>
            <div v-if="$store.state.pmid == 219" class="content">
              <div class="item">1. <el-link type="primary" @click="sxglTableExport">实习管理表 <i
                    class="el-icon-download"></i></el-link>
              </div>

            </div>
            <div v-else class="content">
              <div class="item">1. <el-link type="primary" disabled>实习管理表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">2. <el-link type="primary" disabled>应届毕业生就业表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">3. <el-link type="primary" disabled>上届毕业生年终就业表 <i
                    class="el-icon-download"></i></el-link>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col v-if="$store.state.pmid != 214" :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>中职系统</span>
            </div>
            <div v-if="$store.state.pmid == 219" class="content">
              <div class="item">1. <el-link type="primary" @click="handleDownload219('sxsp')">实习过程采集表 <i
                    class="el-icon-download"></i></el-link>
              </div>

            </div>
            <div v-else class="content">
              <div class="item">1. <el-link type="primary" disabled>实习过程采集表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">2. <el-link type="primary" disabled>实习结果采集表 <i class="el-icon-download"></i></el-link>
              </div>
            </div>

          </el-card>
        </el-col>
        <el-col v-if="$store.state.pmid != 214" :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>{{ $store.state.pmid == 219 ? "靖江市" : "南京市" }}教育局</span>
            </div>
            <div v-if="$store.state.pmid == 219" class="content">
              <div class="item">1. <el-link type="primary" @click="handleDownload('jydc')">就业调查表 <i
                    class="el-icon-download"></i></el-link>
              </div>

            </div>
            <div v-else class="content">
              <div class="item">1. <el-link type="primary" disabled>就业名册表 <i class="el-icon-download"></i></el-link>
              </div>
              <div class="item">2. <el-link type="primary" @click="handleDownload('jydc')">就业调查表 <i
                    class="el-icon-download"></i></el-link></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>常规表格</span>
            </div>
            <div class="content">
              <div class="item">1. <el-link type="primary" @click="handleDownload('base')">学生基础信息 <i
                    class="el-icon-download"></i></el-link></div>
              <div v-if="$store.state.pmid != 219" class="item">2. <el-link type="primary" disabled>学生实习过程信息 <i
                    class="el-icon-download"></i></el-link>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="always">
            <div slot="header" class="clearfix">
              <span>其他</span>
            </div>
            <div class="content">
              <div class="item">1. <el-link type="primary" @click="handleDownload('sxmc')">实习名册 <i
                    class="el-icon-download"></i></el-link></div>
              <div class="item">2. <el-link type="primary" @click="handleDownload('sxsp')">实习审批表 <i
                    class="el-icon-download"></i></el-link></div>
              <div class="item">3. <el-link type="primary" @click="dialogExportSxbg = true">实习报告 <i
                    class="el-icon-download"></i></el-link></div>
              <div class="item">4. <el-link type="primary" @click="sxglTableExport">实习管理表 <i
                    class="el-icon-download"></i></el-link></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="请选择下载数据项" :visible.sync="dialogShow" width="1000px" top="2%" :close-on-click-modal="false"
      custom-class="cus_dialog" @close="closeDialog">
      <div style="padding:0 100px;height: 100%; overflow: auto;">
        <div>
          <el-checkbox label="全选" border v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>

        </div>
        <el-checkbox-group v-model="checkList" @change="handleCheckedGroupChange">
          <div v-for="(item, idx) in downItemList" :key="idx"
            style="display: inline-block; width: 25%; padding-bottom: 5px;">
            <el-checkbox :label="item.id" :value="item.id" :checked="item.rid">{{ item.title }}</el-checkbox>
          </div>
        </el-checkbox-group>

        <div style="padding-top:20px;text-align: right; ">

        </div>
      </div>


      <div style="position: absolute; bottom: 10px; right:50px">
        <el-button type="primary" @click="startDown()" icon="el-icon-checked">下载</el-button>
      </div>


    </el-dialog>

    <el-dialog title="导出实习报告" class="cus_dialog" :visible.sync="dialogExportSxbg" width="25%" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">导出实习报告（默认导出所有）</span>
      </div>
      <el-form>
        <el-form-item label="选择届次">
          <el-select v-model="searchForm.jc" placeholder="请选择届次" clearable>
            <el-option v-for="j in jieci" :key="j" :value="j" :label="j"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择月份">
          <el-date-picker v-model="searchForm.report_month" value-format="yyyy-MM" type="month" placeholder="选择月">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogExportSxbg = false">取 消</el-button>
        <el-button type="primary" @click="exportReport()">导出</el-button>
      </div>
    </el-dialog>



  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import { exportWord, exportWordBlob } from "@/utils/exportword";
import { njxx_sxmc_blob, njxx_sxmc_export } from "../../../public/js/Export2Excel";
import { downloadZip } from "@/utils/downloadZip";

export default {
  components: { tchtree },
  data() {
    return {
      searchForm: {
        class_ids: ""
      },
      dialogShow: false,
      downItemList: [],
      checkAll: false,
      checkList: [],
      deptType: "xx",
      sxbgTimer: null,
      dialogExportSxbg: false,
    }
  },
  mounted() {
  },
  computed: {
    jieci() {
      let m = this.$moment().month() + 1
      let year = this.$moment().year()
      if (m < 9) {
        year--
      }
      let years = []
      for (let i = 0; i < 5; i++) {
        years.push(year - i)
      }
      //this.searchForm.jc = years[0]
      return years
    },
  },
  methods: {
    handleDownload(type) {
      if (!this.searchForm.class_ids) {
        this.deptType = 'xx'
      }
      if (type == 'sxmc') {
        this.$http.post('/api/stu_enter_mc_export2', {
          class_ids: this.searchForm.class_ids,
          dept_type: this.deptType,
        }).then(res => {
          let data = res.data
          let filename = ""
          let items = []
          if (data && data.length > 1) {
            if (this.deptType == 'yx' || this.deptType == 'jc') {
              filename = data && data.length > 0 ? data[0]['xibu_name'] + "-" : ""
            }
            for (let item of data) {
              let obj = {}
              if (this.deptType == 'xx') {
                obj.filename = item.xibu_name
                obj.children = []
                for (let classItem of item.classes) {
                  obj.children.push(this.handleExportSxmcData(classItem, true))
                }
              } else {
                obj = this.handleExportSxmcData(item, true)
              }
              items.push(obj)
            }
            downloadZip(items, `${filename}毕业生实习名册`)
          } else {
            let info = data[0];
            let exportData = this.handleExportSxmcData(info)
            filename = info.class_name ? info.class_name + '-' : ''
            if (window.pmid == 210 || window.pmid == 214) {
              filename = `${filename}毕业生顶岗实习名册.xlsx`
              njxx_sxmc_export(exportData, filename)
            } else {
              exportWord('./docs/毕业班实习生名册模板.docx', exportData, `${filename}毕业班实习生名册.docx`)
            }
          }
        })
      } else if (type == 'sxsp') {
        this.$http.post('/api/t_sch_stu_enter_export', {
          class_ids: this.searchForm.class_ids
        }).then(res => {
          let data = res.data
          data.classes.map(d => {
            d.teacher_name = d.teacher_name ?? ""
            d.phone = d.phone ?? ""
            d.mentor_name = d.mentor_name ?? ""
            d.mentor_tel = d.mentor_tel ?? ""
            d.start_time = d.start_time.replaceAll('-', '/')
            d.end_time = d.end_time.replaceAll('-', '/')
            d.major_name = d.major_name || d.yx_name
            if (window.pmid == 226) {
              if (d.class_name && (d.class_name.indexOf('护理') > -1 || d.class_name.indexOf('助产') > -1)) {
                d.mentor_name = '尹健'
                d.mentor_tel = '15850597280'
              }
              if (d.class_name && (d.class_name.indexOf('护理') < 0 && d.class_name.indexOf('助产') < 0)) {
                d.mentor_name = '郑秀明'
                d.mentor_tel = '13951824274'
              }
            }
          })
          data.enters.map(e => {
            e.department = e.department ?? ""
            e.job = e.job ?? ""
            e.remarks = e.remarks ?? ""
            e.firm_content_name = e.firm_content_name ?? ""
            e.firm_content_tel = e.firm_content_tel ?? ""
            e.salary = e.salary ?? ""
            e.xinzi = e.xinzi ?? ""
            e.enter_info = e.enter_info ?? ""
            if (window.pmid == 226) {
              e.enter_info = '公立'
              e.salary = '2000元以下'
              if (e.firm_name && (e.firm_name.indexOf('明基') > -1 || e.firm_name.indexOf('同仁') > -1 || e.firm_name.indexOf('泰康') > -1 || e.firm_name.indexOf('紫金') > -1)) {
                e.enter_info = '民营'
              }
              if (e.class_name && (e.class_name.indexOf('护理') > -1 || e.class_name.indexOf('助产') > -1)) {
                e.department = '护理部'
                e.job = '护理'
                e.yx_wd = '未定'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('康复') > -1) {
                e.department = '康复科'
                e.job = '康复'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('老年') > -1) {
                e.department = '养老护理部'
                e.job = '护理员'
                e.enter_info = '民营'
              }
              if (window.pmid == 226 && e.class_name && (e.class_name.indexOf('医电') > -1 || e.class_name.indexOf('影像') > -1 || e.class_name.indexOf('放射') > -1)) {
                e.department = '影像科'
                e.job = '技士'
              }
            }
          })
          if (this.deptType != 'bj') {
            this.handleExportSxsqData(data)
          } else {
            let templateName = "毕业实习审批表模板2.docx"
            if (window.pmid == 210 || window.pmid == 214) {
              templateName = "毕业实习审批表模板.docx"
            }
            let date = new Date()
            data.year = date.getFullYear()
            data.month = date.getMonth() + 1
            data.day = date.getDate()
            exportWord(`./docs/${templateName}`, data, `${data.classes[0].class_name}-毕业实习审批表.docx`)
          }
        })
      } else if (type == 'jydc') {
        this.$http.post('/api/export_jydc_word', {
          pmid: window.pmid
        }).then(res => {
          let data = res.data
          let config = JSON.parse(localStorage.getItem('config'))
          data.school_name = config.schoolname
          data.year = new Date().getFullYear()
          data.jy_rate = data.jy_rate > 0 ? `${data.jy_rate * 100}%` : 0
          data.dk_rate = data.dk_rate > 0 ? `${data.dk_rate * 100}%` : 0
          exportWord('./docs/毕业生就业情况表.docx', data, '毕业生就业情况表.docx')
        })
      } else if (type == 'base') {
        this.getDownZidian()

      }
    },
    handleDownload219(type) {
      if (!this.searchForm.class_ids) {
        this.deptType = 'xx'
      }
      if (type == 'sxmc') {
        this.$http.post('/api/stu_enter_mc_export2', {
          class_ids: this.searchForm.class_ids,
          dept_type: this.deptType,
        }).then(res => {
          let data = res.data
          let filename = ""
          let items = []
          if (data && data.length > 1) {
            if (this.deptType == 'yx' || this.deptType == 'jc') {
              filename = data && data.length > 0 ? data[0]['xibu_name'] + "-" : ""
            }
            for (let item of data) {
              let obj = {}
              if (this.deptType == 'xx') {
                obj.filename = item.xibu_name
                obj.children = []
                for (let classItem of item.classes) {
                  obj.children.push(this.handleExportSxmcData(classItem, true))
                }
              } else {
                obj = this.handleExportSxmcData(item, true)
              }
              items.push(obj)
            }
            downloadZip(items, `${filename}生源信息表`)
          } else {
            let info = data[0];
            let exportData = this.handleExportSxmcData(info)
            filename = info.class_name ? info.class_name + '-' : ''
            if (window.pmid == 210 || window.pmid == 214) {
              filename = `${filename}毕业生顶岗实习名册.xlsx`
              njxx_sxmc_export(exportData, filename)
            } else {
              exportWord('./docs/毕业班实习生名册模板.docx', exportData, `${filename}生源信息表.docx`)
            }
          }
        })
      } else if (type == 'sxsp') {
        this.$http.post('/api/t_sch_stu_enter_export', {
          class_ids: this.searchForm.class_ids
        }).then(res => {
          let data = res.data
          data.classes.map(d => {
            d.teacher_name = d.teacher_name ?? ""
            d.phone = d.phone ?? ""
            d.mentor_name = d.mentor_name ?? ""
            d.mentor_tel = d.mentor_tel ?? ""
            d.start_time = d.start_time.replaceAll('-', '/')
            d.end_time = d.end_time.replaceAll('-', '/')
            d.major_name = d.major_name || d.yx_name
            if (window.pmid == 226) {
              if (d.class_name && (d.class_name.indexOf('护理') > -1 || d.class_name.indexOf('助产') > -1)) {
                d.mentor_name = '尹健'
                d.mentor_tel = '15850597280'
              }
              if (d.class_name && (d.class_name.indexOf('护理') < 0 && d.class_name.indexOf('助产') < 0)) {
                d.mentor_name = '郑秀明'
                d.mentor_tel = '13951824274'
              }
            }
          })
          data.enters.map(e => {
            e.department = e.department ?? ""
            e.job = e.job ?? ""
            e.remarks = e.remarks ?? ""
            e.firm_content_name = e.firm_content_name ?? ""
            e.firm_content_tel = e.firm_content_tel ?? ""
            e.salary = e.salary ?? ""
            e.xinzi = e.xinzi ?? ""
            e.enter_info = e.enter_info ?? ""
            if (window.pmid == 226) {
              e.enter_info = '公立'
              e.salary = '2000元以下'
              if (e.firm_name && (e.firm_name.indexOf('明基') > -1 || e.firm_name.indexOf('同仁') > -1 || e.firm_name.indexOf('泰康') > -1 || e.firm_name.indexOf('紫金') > -1)) {
                e.enter_info = '民营'
              }
              if (e.class_name && (e.class_name.indexOf('护理') > -1 || e.class_name.indexOf('助产') > -1)) {
                e.department = '护理部'
                e.job = '护理'
                e.yx_wd = '未定'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('康复') > -1) {
                e.department = '康复科'
                e.job = '康复'
              }
              if (window.pmid == 226 && e.class_name && e.class_name.indexOf('老年') > -1) {
                e.department = '养老护理部'
                e.job = '护理员'
                e.enter_info = '民营'
              }
              if (window.pmid == 226 && e.class_name && (e.class_name.indexOf('医电') > -1 || e.class_name.indexOf('影像') > -1 || e.class_name.indexOf('放射') > -1)) {
                e.department = '影像科'
                e.job = '技士'
              }
            }
          })
    
            let templateName = "毕业实习审批表模板2.docx"
            if (window.pmid == 210 || window.pmid == 214) {
              templateName = "毕业实习审批表模板.docx"
            }
            let date = new Date()
            data.year = date.getFullYear()
            data.month = date.getMonth() + 1
            data.day = date.getDate()
            exportWord(`./docs/${templateName}`, data, `${data.classes[0].class_name}-实习过程采集表.docx`)
          
        })
      } else if (type == 'jydc') {
        this.$http.post('/api/export_jydc_word', {
          pmid: window.pmid
        }).then(res => {
          let data = res.data
          let config = JSON.parse(localStorage.getItem('config'))
          data.school_name = config.schoolname
          data.year = new Date().getFullYear()
          data.jy_rate = data.jy_rate > 0 ? `${data.jy_rate * 100}%` : 0
          data.dk_rate = data.dk_rate > 0 ? `${data.dk_rate * 100}%` : 0
          exportWord('./docs/毕业生就业情况表.docx', data, '毕业生就业情况表.docx')
        })
      } else if (type == 'base') {
        this.getDownZidian()

      }
    },
    getCurrentAgeByBirthDate(strBirthday) {
      /**
       * 通过出生日期获取当前年龄
       * @param strBirthday：指的是出生日期，格式为"1990-01-01",字符串类型
       */
      // 将出生日期的字符串通过"-"分割成数组
      const strBirthdayArr = strBirthday.split("-")
      // 拿到出生日期的年
      const birthYear = strBirthdayArr[0]
      // 拿到出生日期的月
      const birthMonth = strBirthdayArr[1]
      // 拿到出生日期的日
      const birthDay = strBirthdayArr[2]
      // 创建一个时间对象
      const d = new Date()
      // 拿到当前时间的年
      const nowYear = d.getFullYear()
      // 拿到当前时间的月
      const nowMonth = d.getMonth() + 1
      // 拿到当前时间的日
      const nowDay = d.getDate()
      // 如果出生日期的年等于当前时间的年
      if (nowYear === birthYear) return 0 // 返回周岁年龄 0,并终止函数执行
      // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
      const ageDiff = nowYear - birthYear; // 年之差
      // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
      if (ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
      // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
      if (nowMonth !== birthMonth) {
        // 拿到出生日期的日与当前时间的月之差
        const monthDiff = nowMonth - birthMonth; // 月之差
        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
      }
      // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
      const dayDiff = nowDay - birthDay;
      // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
      return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
    },
    closeDialog() {
      this.dialogShow = false
    },
    getDownZidian() {
      this.$http.post("/api/role_student_dataitem").then(res => {
        this.downItemList = res.data
        this.dialogShow = true
      })
    },
    handleCheckAllChange() {

      if (this.checkAll) {
        this.checkList = this.downItemList.map(e => { return e.id })

      } else {
        this.checkList = []
      }

    },
    handleCheckedGroupChange() {
      if (this.checkList.length != this.downItemList.length) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    startDown() {

      if (this.checkList.length > 0) {
        this.$http.post('/api/down_role_student_xls', {
          class_ids: this.searchForm.class_ids,
          checkList: this.checkList
        }).then(res => {
          if (res.data.url) {
            window.open(res.data.url, "_blank")
          }
        })
      } else {
        this.$message.error('请选择下载数据项')
      }
    },
    changeType(val) {
      this.deptType = val
    },
    handleExportSxmcData(item, isBlob = false) {
      item.year = new Date().getFullYear()
      item.major_name = item.major_name || item.xibu_name
      let num = 1
      item.students.map(s => {
        s.yx_ly = ''
        s.yx_wd = '未定'
        s.yx_wu = ''

        if (window.pmid == 226 && item.class_name && (item.class_name.indexOf('护理') > -1 || item.class_name.indexOf('助产') > -1)) {
          s.department = '护理部'
          s.job = '护理'
          s.yx_wd = '未定'
        }
        if (window.pmid == 226 && item.class_name && item.class_name.indexOf('康复') > -1) {
          s.department = '康复科'
          s.job = '康复'
        }
        if (window.pmid == 226 && item.class_name && item.class_name.indexOf('老年') > -1) {
          s.department = '养老护理部'
          s.job = '护理员'
        }
        if (window.pmid == 226 && item.class_name && (item.class_name.indexOf('医电') > -1 || item.class_name.indexOf('影像') > -1 || item.class_name.indexOf('放射') > -1)) {
          s.department = '影像科'
          s.job = '技士'
        }
        s.age = 0
        if (s.birthday) {
          s.age = this.getCurrentAgeByBirthDate(s.birthday)
        }
        s.id = num
        s.zzmm = s.zzmm == '团员' ? "是" : "否"
        s.gender = s.gender == 1 ? "男" : '女'
        s.phone = s.phone ?? ""
        s.remarks = s.remarks ?? ''
        s.jtzz = s.jtzz ?? ''
        num++
      })
      if (window.pmid == 210 || window.pmid == 214) {
        let excelData = [
          [`${item.year}届毕业生顶岗实习名册`],
          ['填表说明：1.学校名称、实习单位名称请完整填写。\n' +
            '2.专业名称请按照专业目录规范填写。\n' +
            '3.实习起止日期请按实际填写。\n' +
            '4.录取意向：留用、未定、不留用'],
          [`学校：${item.school_name}                  专业：${item.major_name}                班级：${item.class_name}                班主任：${item.teacher_name}         联系方式：${item.phone}`],
          ['序号', '姓名', '性别', '年龄', '实习单位', '实习部门', '实习岗位', '实习时间', '', '录取意向', '联系方式'],
          ['', '', '', '', '', '', '', '起', '止', '', '']
        ]
        item.students.forEach(stu => {
          excelData.push([
            stu.id,
            stu.stu_name,
            stu.gender,
            stu.age,
            stu.firm_name,
            stu.department,
            stu.job,
            stu.start_time,
            stu.end_time,
            stu.start_time ? '留用' : '未定',
            stu.phone,
          ])
        })
        if (isBlob) {
          return {
            blob: njxx_sxmc_blob(excelData),
            filename: `${item.class_name ? item.class_name + '-' : ''}毕业生顶岗实习名册.xlsx`
          }
        }
        return excelData
      }

      if (isBlob) {
        return {
          blob: exportWordBlob('./docs/毕业班实习生名册模板.docx', item),
          filename: `${item.class_name ? item.class_name + '-' : ''}毕业班实习生名册.docx`
        }
      }
      return item
    },
    handleExportSxsqData(data) {
      let zipName = ""
      let templateName = "毕业实习审批表模板2.docx"
      if (window.pmid == 210 || window.pmid == 214) {
        templateName = "毕业实习审批表模板.docx"
      }
      let date = new Date()
      let wordBlob = []
      let wordData = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        school_name: data.school_name,
      }
      let enters = data.enters
      let entersObj = {}
      enters.forEach(e => {
        if (!entersObj[e.class_id]) {
          entersObj[e.class_id] = []
        }
        entersObj[e.class_id].push(e)
      })

      if (this.deptType == 'xx') {
        let xibuObj = {}
        data.classes.forEach(c => {
          if (!xibuObj[c.yx_name]) {
            xibuObj[c.yx_name] = []
          }
          xibuObj[c.yx_name].push(c)
        })
        let xibus = Object.keys(xibuObj)
        for (let xibu of xibus) {
          let blobObj = {
            filename: xibu,
            children: [],
          }
          let classes = xibuObj[xibu] // 系部下的班级
          for (let classInfo of classes) {
            let enters = entersObj[classInfo.class_id]
            let tmp = { ...wordData, classes: [classInfo], enters: enters }
            let blob = exportWordBlob(`./docs/${templateName}`, tmp)
            blobObj.children.push({ filename: `${classInfo.class_name}-毕业实习审批表.docx`, blob })
          }
          wordBlob.push(blobObj)
        }
      } else {
        for (let classInfo of data.classes) {
          let enters = entersObj[classInfo.class_id]
          let tmp = { ...wordData, classes: [classInfo], enters: enters }
          let blob = exportWordBlob(`./docs/${templateName}`, tmp)
          wordBlob.push({ filename: `${classInfo.class_name}-毕业实习审批表.docx`, blob })
        }
        zipName = `${data.classes[0].yx_name}-`
      }
      downloadZip(wordBlob, `${zipName}毕业实习审批表`)
    },
    exportReport() {
      let loadingInstance = this.$loading({
        lock: true,
        text: '正在处理中，请稍等片刻....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      })
      this.$http.post('/api/export_sx_report', {
        class_id: this.searchForm.class_ids,
        jc: this.searchForm.jc,
        report_month: this.searchForm.report_month,
      }).then(res => {
        var timer = setTimeout(() => {
          this.$message.error('下载失败，请重试！')
          clearInterval(this.sxbgTimer)
          loadingInstance.close()
        }, 60000)
        this.sxbgTimer = setInterval(() => {
          this.$http.post('/api/delayedDownloadFile', {
            filename: res.data,
          }).then(r => {
            if (r.data.url) {
              clearTimeout(timer)
              clearInterval(this.sxbgTimer)
              loadingInstance.close()
              this.dialogExportSxbg = false
              window.open(r.data.url, '_blank')
            }
          })
        }, 5000)
      }).catch(err => {
        loadingInstance.close()
      })
    },
    sxglTableExport() {
      this.$http.post('/api/sxglTableExportToExcel', {
        class_ids: this.searchForm.class_ids,
        dept_type: this.deptType,
      }).then(res => {
        if (res.data.url) {
          if (this.deptType == 'bj') {
            this.$http.get(res.data.url, {
              responseType: 'arraybuffer'
            }).then(r => {
              var blob = new Blob([r.data], { type: 'application/octet-stream;charset=UTF-8' })
              var downloadElement = document.createElement('a')
              var href = window.URL.createObjectURL(blob) // 创建下载的链接
              downloadElement.style.display = 'none'
              downloadElement.href = href
              downloadElement.download = decodeURIComponent(res.data.filename) // 下载后文件名
              document.body.appendChild(downloadElement)
              downloadElement.click() // 点击下载
              document.body.removeChild(downloadElement) // 下载完成移除元素
              window.URL.revokeObjectURL(href)
            })
          } else {
            window.open(res.data.url, '_blank')
          }
        }
      })
    },
  },
  beforeDestroy() {
    clearInterval(this.sxbgTimer)
  }
};
</script>

<style scoped>
.el-card {
  margin-bottom: 12px;
}

.item {
  padding: 5px 0
}

.content {
  height: 120px;
}
</style>